import { IoMdArrowBack } from "react-icons/io";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import AuthContext from "../contexts/AuthContext";
import { toast } from "react-toastify";
import ImageDropzone from "../containers/ImageDropzone";
import MenuItem from "../components/MenuItem";
import {
  DE,
  GB,
  ES,
  FR,
  IT,
  TR,
  BA,
  RS,
  HR,
  ME,
  AL,
  SI,
  NL,
  AT,
  US,
} from "country-flag-icons/react/3x2";
import arapskaZastava from "../photos/arapska-liga-zastava.png";
import Tags from "../components/Tags";
import { fetchPlace, updatePlace } from "../apis";
import MainLayout from "../layouts/MainLayout";
import AddTags from "../components/AddTags";

const Panel = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
`;

const MenuSettings = () => {
  const [place, setPlace] = useState({});
  const [font, setFont] = useState("");
  const [color, setColor] = useState("");
  const [background_color, setBgColor] = useState("");
  const [color_1, setTextColor] = useState("#000000");
  const [color_2, setBorderColor] = useState("#000000");
  const [color_3, setCartTextColor] = useState("#FFF");
  const [color_4, setCartBgColor] = useState("#000000");
  const [tagAdded, setTagAdded] = useState(false);

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(place.image);
  const [background_image, setBackgroundImage] = useState(
    place.background_image
  );
  const [number_of_tables, setNumberOfTables] = useState(
    place.number_of_tables
  );

  const [instagram_url, setInstagram] = useState("");
  const [korpa_url, setKorpa] = useState("");
  const [glovo_url, setGlovo] = useState("");
  const [client_url, setWeb] = useState("");
  const [trip_advisor_url, setAdviser] = useState("");
  const [google_reviews_url, setGoogle] = useState("");
  const [enabled_languages, setEnabledLanguages] = useState([]);
  const [ordering_system, setOrderingSystem] = useState(place.ordering_system);
  const [password, setPlacePassword] = useState("");
  const [currency, setCurrency] = useState("");

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const params = useParams();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const onBack = () => navigate(`/places/${params.id}`);

  const onFetchPlace = async () => {
    const json = await fetchPlace(params.id);
    if (json) {
      setPlace(json);
      setFont(json.font);
      setColor(json.color);
      setTextColor(json.color_1);
      setBorderColor(json.color_2);
      setCartTextColor(json.color_3 ?? "#000000");
      setCartBgColor(json.color_4 ?? "#000000");
      setBgColor(json.background_color);
      setInstagram(json.instagram_url);
      setAdviser(json.trip_advisor_url ?? "");
      setGoogle(json.google_reviews_url);
      setKorpa(json.korpa_url);
      setGlovo(json.glovo_url);
      setWeb(json.client_url);
      setImage(json.image);
      setNumberOfTables(json.number_of_tables);
      setBackgroundImage(json.background_image);
      setEnabledLanguages(json.enabled_languages);
      setOrderingSystem(json.ordering_system);
      setPlacePassword(json.password);
      setCurrency(json.currency);
    }
  };

  const onUpdatePlace = async () => {
    setLoading(true);
    const json = await updatePlace(
      place.id,
      {
        font,
        color,
        instagram_url,
        korpa_url,
        trip_advisor_url,
        google_reviews_url,
        glovo_url,
        client_url,
        image,
        color_1,
        color_2,
        color_3,
        color_4,
        background_color,
        background_image,
        enabled_languages,
        number_of_tables,
        ordering_system,
        password,
        currency,
      },
      auth.token
    );
    if (json) {
      toast("New settings is updated", { type: "success" });
      setPlace(json);
      localStorage.setItem("place", JSON.stringify(json));

      setLoading(false);
    }
  };

  useEffect(() => {
    onFetchPlace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Container = styled.div`
    b,
    p {
      ${({ font }) => font && `font-family: ${font};`}
    }
  `;

  const Place = styled.div`
    text-align: center;
    img {
      border-radius: 5px;
      margin-bottom: 20px;
    }
  `;

  const handleClick = (e) => {
    const { value, checked } = e.target;
    let prm = place;
    prm.enabled_languages = enabled_languages;

    if (checked) {
      setEnabledLanguages([...enabled_languages, value]);
      setPlace(prm);
    } else {
      setEnabledLanguages(enabled_languages.filter((e) => e !== value));
      setPlace(prm);
    }
  };

  return (
    <MainLayout>
      <div className="d-flex align-items-center mb-4">
        <Button variant="link" onClick={onBack}>
          <IoMdArrowBack size={25} color="black" />
        </Button>
        <h3 className="mb-0 mr-2 ml-2">Menu Settings</h3>
      </div>

      <Row>
        {/* LEFT SIDE */}
        <Col md={4}>
          <Panel className="mb-5">
            <Tags tagAdded={tagAdded} />
          </Panel>
          <Panel className={"mb-5"}>
            <AddTags tagAdded={tagAdded} setTagAdded={setTagAdded} />
          </Panel>
          <Panel>
            <Form.Group>
              <Form.Label>Font</Form.Label>
              <Form.Control
                as="select"
                value={font}
                onChange={(e) => setFont(e.target.value)}
              >
                <option>Inter</option>
                <option>Nunito</option>
                <option>Open Sans</option>
                <option>Montserrat</option>
                <option>Poppins</option>
                <option>Delicious Handrawn</option>
                <option>Bebas Neue</option>
                <option>Poppins</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Font Color</Form.Label>
              <ChromePicker
                color={color}
                onChange={(value) => setColor(value.hex)}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Background Color</Form.Label>
              <ChromePicker
                color={background_color}
                onChange={(value) => setBgColor(value.hex)}
                value={background_color}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Online Menu text color</Form.Label>
              <ChromePicker
                color={color_1}
                onChange={(value) => setTextColor(value.hex)}
                value={color_1}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Border Color</Form.Label>
              <ChromePicker
                color={color_2}
                onChange={(value) => setBorderColor(value.hex)}
                value={color_2}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cart Text Color</Form.Label>
              <ChromePicker
                color={color_3}
                onChange={(value) => setCartTextColor(value.hex)}
                value={color_3}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cart Background Color</Form.Label>
              <ChromePicker
                color={color_4}
                onChange={(value) => setCartBgColor(value.hex)}
                value={color_4}
                disableAlpha
                width="100%"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Image</Form.Label>
              <ImageDropzone
                style={{ width: "100%", height: "100%" }}
                value={image}
                onChange={setImage}
              />
              <Form.Label>Background Image</Form.Label>
              <ImageDropzone
                style={{ width: "100%", height: "100%" }}
                value={background_image}
                onChange={setBackgroundImage}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                label="Ordering System"
                checked={ordering_system}
                onChange={(e) => setOrderingSystem(!ordering_system)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Place password"
                value={password}
                onChange={(e) => setPlacePassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Website</Form.Label>
              <Form.Control
                type="text"
                placeholder="Website link"
                value={client_url}
                onChange={(e) => setWeb(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                type="text"
                placeholder="Instagram link"
                value={instagram_url}
                onChange={(e) => setInstagram(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Korpa</Form.Label>
              <Form.Control
                type="text"
                placeholder="Korpa link"
                value={korpa_url}
                onChange={(e) => setKorpa(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Glovo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Glovo link"
                value={glovo_url}
                onChange={(e) => setGlovo(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Trip Advisor</Form.Label>
              <Form.Control
                type="text"
                placeholder="Trip Advisor link"
                value={trip_advisor_url}
                onChange={(e) => setAdviser(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Google Review</Form.Label>
              <Form.Control
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
                type="text"
                placeholder="Google review link"
                value={google_reviews_url}
                onChange={(e) => setGoogle(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <p>Choose languages you want to use:</p>
            <Form.Group
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(30px, 1fr))",
                gap: "10px",
                gridAutoFlow: "row",
              }}
            >
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <BA style={{ width: "20px", paddingBottom: "5px" }}></BA>
                <Form.Check
                  type="checkbox"
                  value="bs"
                  checked={enabled_languages.includes("bs")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <DE style={{ width: "20px", paddingBottom: "5px" }}></DE>
                <Form.Check
                  type="checkbox"
                  value="de"
                  checked={enabled_languages.includes("de")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <GB style={{ width: "20px", paddingBottom: "5px" }}></GB>
                <Form.Check
                  type="checkbox"
                  value="en"
                  checked={enabled_languages.includes("en")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <ES style={{ width: "20px", paddingBottom: "5px" }}></ES>
                <Form.Check
                  type="checkbox"
                  value="es"
                  checked={enabled_languages.includes("es")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <FR style={{ width: "20px", paddingBottom: "5px" }}></FR>
                <Form.Check
                  type="checkbox"
                  value="fr"
                  checked={enabled_languages.includes("fr")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <IT style={{ width: "20px", paddingBottom: "5px" }}></IT>
                <Form.Check
                  type="checkbox"
                  value="it"
                  checked={enabled_languages.includes("it")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <TR style={{ width: "20px", paddingBottom: "5px" }}></TR>
                <Form.Check
                  type="checkbox"
                  value="tr"
                  checked={enabled_languages.includes("tr")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  alt="arab-flag"
                  src={arapskaZastava}
                  style={{ width: "20px", paddingBottom: "5px" }}
                ></img>
                <Form.Check
                  type="checkbox"
                  value="sa"
                  checked={enabled_languages.includes("sa")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <RS style={{ width: "20px", paddingBottom: "5px" }}></RS>
                <Form.Check
                  type="checkbox"
                  name="Serbian Latin"
                  value="rs"
                  checked={enabled_languages.includes("rs")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <RS style={{ width: "20px", paddingBottom: "5px" }}></RS>
                <Form.Check
                  name="Serbian Cyrillic"
                  type="checkbox"
                  value="sr"
                  checked={enabled_languages.includes("sr")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <HR style={{ width: "20px", paddingBottom: "5px" }}></HR>
                <Form.Check
                  type="checkbox"
                  value="hr"
                  checked={enabled_languages.includes("hr")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <ME style={{ width: "20px", paddingBottom: "5px" }}></ME>
                <Form.Check
                  type="checkbox"
                  value="me"
                  checked={enabled_languages.includes("me")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <AL style={{ width: "20px", paddingBottom: "5px" }}></AL>
                <Form.Check
                  type="checkbox"
                  value="al"
                  checked={enabled_languages.includes("al")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <SI style={{ width: "20px", paddingBottom: "5px" }}></SI>
                <Form.Check
                  type="checkbox"
                  value="sl"
                  checked={enabled_languages.includes("sl")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <NL style={{ width: "20px", paddingBottom: "5px" }}></NL>
                <Form.Check
                  type="checkbox"
                  value="nl"
                  checked={enabled_languages.includes("nl")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <AT style={{ width: "20px", paddingBottom: "5px" }}></AT>
                <Form.Check
                  type="checkbox"
                  value="at"
                  checked={enabled_languages.includes("at")}
                  onChange={handleClick}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <US style={{ width: "20px", paddingBottom: "5px" }}></US>
                <Form.Check
                  type="checkbox"
                  value="en-us"
                  checked={enabled_languages.includes("en-us")}
                  onChange={handleClick}
                />
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Select Currency</Form.Label>
              <Form.Control
                as="select"
                value={currency}
                onChange={handleCurrencyChange}
              >
                <option value="KM">Bosanska Marka - KM</option>
                <option value="RSD">Srpski Dinar - RSD</option>
                <option value="€">Euro - €</option>
                <option value="ALL">Albanski Lek - ALL</option>
                <option value="CHF">Švicarski franak CHF</option>
                <option value="$">Dolar - $</option>
              </Form.Control>
            </Form.Group>

            <Button
              className="mt-4"
              variant="standard"
              block
              onClick={onUpdatePlace}
              disabled={loading}
            >
              Save Setings
            </Button>
          </Panel>
        </Col>

        {/* RIGHT SIDE */}
        <Col md={8}>
          <Container font={font}>
            <Place>
              <img alt="place-image" src={place.image} width="30%" />
              <h3>
                <b>{place.name}</b>
              </h3>
            </Place>
            {place?.categories
              ?.filter(
                (category) =>
                  category.menu_items.filter((i) => i.is_available).length
              )
              .map((category) => (
                <div key={category.id} className="mt-5">
                  <h4 className="mb-4">
                    <b>{category.name_bs}</b>
                  </h4>
                  <div style={{ display: "grid", rowGap: "20px" }}>
                    {category.menu_items
                      .filter((item) => item.is_available)
                      .map((item) => (
                        <MenuItem
                          currency={place.currency}
                          key={item.id}
                          item={item}
                          color={color}
                          bgColor={background_color}
                        />
                      ))}
                  </div>
                </div>
              ))}
          </Container>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default MenuSettings;
