import { useState, createContext } from "react";
import { useEffect } from "react";
import '../index.css'

const LanguageContext = createContext('')

export function LanguageProvider({children}){
    
    const [language, setLanguage] = useState('')

    
    useEffect(() => {
    setLanguage(localStorage.getItem('dataKey'))

  }, [language]);

    return(
        <LanguageContext.Provider value={{setLanguage, language}}>
            {children}
        </LanguageContext.Provider>
    )
}
export default LanguageContext