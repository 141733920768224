import React, { useEffect } from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Item from "./Item";
import { useState } from "react";
import { useContext } from "react";
import LanguageContext from "../contexts/LanguageContext";
import arrow from "../photos/strelica.svg";
import { PiShoppingCartSimpleFill } from "react-icons/pi";
import Translation from "./Translation";
import Kolica from "../photos/korpa1.svg";

const Container = styled.div`
  b,
  p {
    ${({ font }) => font && `font-family: ${font};`}
  }
`;

const MenuList = ({
  place,
  shoppingCart = {},
  showShoppingCart,
  setShowShoppingCart,
  totalQuantity,
  onOrder,
  font = "",
  color = "",
}) => {
  const categoryFromParams = useParams();
  const [shakeCart, setShakeCart] = useState(false);

  const { language } = useContext(LanguageContext);
  let [category, setCategory] = useState();
  let [mainCategory, setMainCategory] = useState();
  const [previousItem, setPreviousItem] = useState(null);
  const { translate } = Translation({ language });

  let mainCategories = useState(
    JSON.parse(localStorage.getItem("mainCategories")) || []
  );
  mainCategories = mainCategories[0];

  const findParentById = (elements, id) => {
    if (!elements && !Array.isArray(elements)) return null;
    for (const element of elements) {
      if (element.id === id) {
        return element;
      } else if (element?.children?.length > 0) {
        const found = findParentById(Array.from(element.children), id);
        if (found) {
          if (!element.parent_category) setMainCategory(element);
          return found;
        }
      }
    }
    return null;
  };
  useEffect(() => {
    const child = findParentById(
      mainCategories,
      parseInt(categoryFromParams.categoryId)
    );
    setCategory(child);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const [menuItemFormShow, setMenuItemFormShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const waiterLogged = localStorage.getItem("waiterLogged");
  const showModal = () => setMenuItemFormShow(true);
  const hideModal = () => {
    if (previousItem === null) {
      setMenuItemFormShow(false);
    } else {
      setSelectedItem(previousItem);
      setPreviousItem(null);
    }
  };
  if (!category) return "";
  return (
    <Container className="menuContainer" font={font}>
      <div
        style={{
          backgroundImage: `url(${place.background_image})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "30vh",
          backgroundPositionY: "center",
          width: "100vw",
          maxWidth: "480px",
        }}
      >
        <div
          style={{
            height: "35vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {mainCategory ? (
            <b
              className="parentCategoryName"
              style={{ color: "white", fontSize: "23px" }}
            >
              {mainCategory["name_" + language]}
            </b>
          ) : (
            <img
              style={{ maxWidth: "60%", marginBottom: "10vh" }}
              src={place.image}
              alt="placeImage"
            ></img>
          )}
        </div>
      </div>
      <div
        style={{
          position: "relative",
          backgroundColor: `${place.background_color}`,
          paddingTop: "70px",
          minHeight: "80vh",
        }}
        className="onlineMenu"
      >
        <img
          src={arrow}
          alt="arrow"
          style={{
            position: "absolute",
            left: "5%",
            top: "30px",
            width: "28px",
            height: "35px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
          className="arrowLeft"
        ></img>
        {(place.ordering_system && totalQuantity) ||
        (waiterLogged === "true" && totalQuantity) ? (
          <div>
            {!showShoppingCart && (
              <span
                style={{
                  position: "absolute",
                  color: "white",
                  zIndex: "10",
                  background: "red",
                  borderRadius: "100%",
                  right: "4%",
                  top: "28px",
                  width: "18px",
                  height: "18px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "13px",
                }}
              >
                {totalQuantity}
              </span>
            )}
            <img
              style={{
                position: "absolute",
                right: "5%",
                top: "27px",
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
              onClick={() => setShowShoppingCart(!showShoppingCart)}
              src={Kolica}
            />
          </div>
        ) : (
          ""
        )}
        {
          <div
            style={{
              display: "grid",
              placeContent: "center",
              marginBottom: "25px",
            }}
          >
            <div
              key={category.id}
              style={{
                backgroundImage: `url(${category?.background_image})`,
                backgroundSize: "cover",
                backgroundBlendMode: "darken",
                backgroundColor: "rgba(0,0,0, 0.3)",
                borderColor: place.color_2,
              }}
              className="oneCategory"
            >
              <b style={{ color: "white" }}>{category["name_" + language]}</b>
            </div>
            <div
              key={category.id}
              style={{
                backgroundImage: `url(${category.background_image})`,
                backgroundSize: "cover",
                backgroundBlendMode: "darken",
                backgroundColor: "rgba(0,0,0, 0.3)",
                borderColor: place.color_2,
              }}
            ></div>
          </div>
        }
        <div
          style={{
            textAlign: "-webkit-center",
            display: "grid",
            gap: "15px",
          }}
        >
          {category?.menu_items &&
            category?.menu_items
              .filter((item) => item.is_available)
              .map((item, index, array) => (
                <div key={index} style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "82%",
                      cursor: "pointer",
                      marginBottom: index === array.length - 1 ? "30px" : "0",
                    }}
                    onClick={() => {
                      setSelectedItem(item);
                      showModal();
                    }}
                  >
                    <MenuItem
                      key={item.id}
                      item={{
                        ...item,
                        quantity: shoppingCart[item.id]?.quantity,
                      }}
                      color={color}
                      font={font}
                    />
                    <amp-ad
                      layout="fixed"
                      width="320"
                      height="50"
                      type="adsense"
                      data-ad-client="ca-pub-7408187303742789"
                      data-ad-slot="5411640593"
                    ></amp-ad>
                  </div>
                </div>
              ))}
        </div>
        {(place.ordering_system && totalQuantity) ||
        (waiterLogged === "true" && totalQuantity) ? (
          <div
            onClick={() => setShowShoppingCart(!showShoppingCart)}
            style={{
              position: "fixed",
              placeContent: "center",
              display: "flex",
              bottom: "0px",
              left: "0",
              right: "0",
            }}
          >
            <div
              style={{
                color: "white",
                fontSize: "15px",
                font: "lato, sans-serif",
                background: place.color_4,
                padding: "9px 35px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                display: "flex",
                gap: "10px",
                color: place?.color_3,
              }}
            >
              {translate("cartContent")}
              <div className="position-relative">
                <span
                  style={{
                    position: "absolute",
                    color: "white",
                    zIndex: "10",
                    background: "red",
                    borderRadius: "100%",
                    width: "15px",
                    height: "15px",
                    cursor: "pointer",
                    left: "50%",
                    fontSize: "10px",
                    color: "white",
                    top: "-2px",
                  }}
                >
                  {totalQuantity}
                </span>

                <PiShoppingCartSimpleFill
                  style={{
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                    paddingBottom: "2px",
                  }}
                  size={30}
                  color={place?.color_3}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <Modal
          id="itemModal"
          className="itemModal"
          show={menuItemFormShow}
          onHide={hideModal}
          centered
        >
          <Modal.Body className="modalBody">
            <Item
              previousItem={previousItem}
              setPreviousItem={setPreviousItem}
              onDone={() => hideModal()}
              setSelectedItem={setSelectedItem}
              shakeCart={shakeCart}
              setShakeCart={setShakeCart}
              onOrder={onOrder}
              ordering={place.ordering_system}
              item={selectedItem}
              color={color}
              shoppingCart={shoppingCart}
              font={font}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  );
};

export default MenuList;
