import { Card, Button } from "react-bootstrap";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import PlaceContext from "../contexts/PlaceContext";

const Order = ({ order, onComplete }) => {
  let details = [];
  try {
    details = JSON.parse(order.detail);
  } catch (error) {
    console.error("Error parsing order details:", error);
  }
  const place = JSON.parse(localStorage.getItem("place")) || [];
  let orderedTime = order.created_at;
  let formattedTime = new Date(orderedTime).toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  let barItems;
  let kitchenItems;

  if (Array.isArray(details)) {
    barItems = details.filter((item) => item.type === "bar");
  }
  if (Array.isArray(details)) {
    kitchenItems = details.filter((item) => item.type === "kitchen");
  }

  const [barDone, setBarDone] = useState(false);
  const [kitchenDone, setKitchenDone] = useState(false);

  const renderItems = (items) =>
    items.map((item) => (
      <div
        className="mb-3 d-flex"
        style={{ flexDirection: "column" }}
        key={item.id}
      >
        <div className="d-flex">
          <span>x{item.quantity}</span>
          <img
            alt="item-image"
            src={item.image}
            width={30}
            height={30}
            style={{ borderRadius: 3, margin: "0 10px" }}
          />
          <span className="mr-3 mb-3">
            {item.name_bs ? item.name_bs : item.name}
          </span>
          {Array.isArray(item.variants) ? (
            <p className="m-0">{item.variants.join(", ")}</p>
          ) : (
            <p className="m-0">{item.variants}</p>
          )}
        </div>
        <div className="d-flex" style={{ columnGap: "10px", flexWrap: "wrap" }}>
          {item.side_dish.length > 0 && <strong>PRILOZI:</strong>}
          {item.side_dish.map((side_dish, index) => (
            <>
              <p className="m-0">{side_dish.side_dish_bs}</p>
              {index < item.side_dish.length - 1 && ","}
            </>
          ))}
        </div>
      </div>
    ));

  const handleDone = () => {
    if (barItems || kitchenItems) {
      if ((barItems && barDone) || (kitchenItems && kitchenDone)) {
        onComplete(order.id);
      } else {
        toast("You need to check order", { type: "error" });
      }
    }
  };

  return (
    <Card style={{ minHeight: "305px" }} className="mb-3">
      <Card.Header className="d-flex justify-content-between position-relative">
        <div style={{ display: "grid" }}>
          <span className="mb-2">
            Order<b>{` #${order.id}`}</b>
          </span>
          <span style={{ maxWidth: "130px" }}>
            <b>{` ${
              order.tables_json
                ? `${JSON.parse(order.tables_json).category} - ${
                    JSON.parse(order.tables_json).tableName
                  }`
                : `Table ${order.table}`
            }`}</b>
          </span>
        </div>

        <span style={{ alignSelf: "center" }}>
          <b>
            {order.amount} {place.currency}
          </b>
        </span>
        <div style={{ display: "grid" }}>
          <span
            style={{ fontSize: "14px" }}
            className="orderTime badge badge-info position-absolute top-[-2px] mb-2"
          >
            {formattedTime}h
          </span>{" "}
          <div style={{ alignSelf: "center" }}>
            {onComplete && order.status === "processing" ? (
              <Button
                style={{
                  height: "40px",
                  width: "80px",
                  backgroundColor: "#228B22",
                  marginLeft: "5px",
                }}
                onClick={handleDone}
              >
                Done
              </Button>
            ) : null}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="d-grid justify-content-between p-0">
        {Object.keys(details).length > 0 ? (
          <div className="d-grid align-items-center">
            {barItems.length > 0 && (
              <div
                className="d-grid p-3"
                style={{
                  backgroundColor: "rgb(255 51 102 / 37%)",
                  position: "relative",
                }}
              >
                {renderItems(barItems)}
                {order.status === "processing" && (
                  <input
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                    type="checkbox"
                    onChange={() => setBarDone(!barDone)}
                  />
                )}
              </div>
            )}

            {kitchenItems.length > 0 && (
              <div
                className="d-grid p-3"
                style={{
                  backgroundColor: "rgb(23 162 184 / 32%)",
                  position: "relative",
                }}
              >
                {renderItems(kitchenItems)}
                {order.status === "processing" && (
                  <input
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                    type="checkbox"
                    onChange={() => setKitchenDone(!kitchenDone)}
                  />
                )}
              </div>
            )}

            {barItems.length === 0 && kitchenItems.length === 0 && (
              <div>No details available</div>
            )}
          </div>
        ) : (
          <div>No details available</div>
        )}
        <div className="orderNapomenaDiv p-3">
          <div className="napomenaDiv">
            <h5 className="napomenaHeading">NAPOMENA</h5>
            <p className="napomenaText">{order.note}</p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Order;
