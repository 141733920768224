import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineDelete, AiOutlineQrcode } from "react-icons/ai";
import { RiFileList3Line } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { Row, Col, Button, Modal, Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { BiEdit } from "react-icons/bi";

import {
  fetchPlace,
  removePlace,
  removeCategory,
  removeMenuItem,
  updatePlace,
  updateCategory,
  fetchMenuItemsByPlace,
  fetchTags,
} from "../apis";
import AuthContext from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import MenuItemForm from "../containers/MenuItemForm";
import MenuItem from "../components/MenuItem";
import QRCodeModal from "../components/QRCodeModal";
import CategoryPopover from "../components/CategoryPopover";
import PlaceContext from "../contexts/PlaceContext";

const Panel = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
`;

const Place = () => {
  const { place, setPlace } = useContext(PlaceContext);
  const [menuItemFormShow, setMenuItemFormShow] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [featuredItemOptions, setFeaturedItemOptions] = useState([]);
  const [qrCode, setQrCode] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [editCategory, setEditCategory] = useState([]);
  const [bgImage, setBgImage] = useState(editCategory.background_image);
  const [parent_category, setParentCategory] = useState(
    editCategory.parent_category
  );

  const [tableCategories, setTableCategories] = useState([]);
  const [numberOfTables, setNumberOfTables] = useState(1);
  const [categoryPriority, setCategoryPriority] = useState();
  const [name, setName] = useState(place.name);
  const [nameModal, setNameModal] = useState(false);

  const showModal = () => setMenuItemFormShow(true);
  const hideModal = () => setMenuItemFormShow(false);

  const showNameModal = () => setNameModal(true);
  const hideNameModal = () => setNameModal(false);

  const showQRModal = () => setQrCode(true);
  const hideQRModal = () => setQrCode(false);

  const showEditModal = () => setEditCategoryModal(true);
  const hideEditModal = () => setEditCategoryModal(false);

  const auth = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();

  const onBack = () => navigate("/places");

  const Container = styled.div`
    opacity: ${({ active }) => (active ? 1 : 0.6)};
  `;

  const [isAvailable, setIsAvailable] = useState(editCategory.is_available);
  const mainCategories = [];

  if (place && place.categories) {
    const data = place.categories;
    const childMap = data.reduce((map, child) => {
      return {
        ...map,
        [child.id]: {
          ...child,
        },
      };
    }, {});

    Object.values(childMap).forEach((child) => {
      if (child.parent_category) {
        if (childMap[child.parent_category]) {
          const parent = childMap[child.parent_category];
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        mainCategories.push(child);
      }
    });
  }

  const fetchMenuItemsForThisPlace = async (placeId) => {
    const json = await fetchMenuItemsByPlace(placeId, auth.token);
    if (json) {
      setFeaturedItemOptions(json);
    }
  };

  const fetchAllTags = async () => {
    const json = await fetchTags(auth.token);
    if (json) {
      setTags(json);
    }
  };

  const onFetchPlace = async () => {
    const json = await fetchPlace(params.id, auth.token);
    if (json) {
      setPlace(json);
      setTableCategories(json.tables_json);
      setNumberOfTables(json.number_of_tables);
      fetchMenuItemsForThisPlace(json.id);
      localStorage.setItem("place", JSON.stringify(json));
    }
  };

  const onRemovePlace = () => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removePlace(params.id, auth.token).then(onBack);
    }
  };

  const onRemoveCategory = (id) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removeCategory(id, auth.token).then(onFetchPlace);
    }
  };

  const onEditCategory = (category) => {
    setEditCategory(category);
    showEditModal();
  };
  const onUpdateCategory = () => {
    updateCategory(
      editCategory.id,
      {
        name_en: editCategory.name_en,
        name_bs: editCategory.name_bs,
        name_de: editCategory.name_de,
        name_es: editCategory.name_es,
        name_fr: editCategory.name_fr,
        name_it: editCategory.name_it,
        name_tr: editCategory.name_tr,
        name_sa: editCategory.name_sa,
        name_rs: editCategory.name_rs,
        name_sr: editCategory.name_sr,
        name_hr: editCategory.name_hr,
        name_me: editCategory.name_me,
        name_al: editCategory.name_al,
        name_sl: editCategory.name_sl,
        name_nl: editCategory.name_nl,
        name_at: editCategory.name_at,
        name_en_us: editCategory.name_en_us,
        background_image: bgImage,
        parent_category: parent_category,
        priority: categoryPriority,
        is_available: isAvailable,
      },
      auth.token
    ).then(onFetchPlace);
    hideEditModal();
  };

  const onRemoveMenuItem = (id) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removeMenuItem(id, auth.token).then(onFetchPlace);
    }
  };

  const onUpdatePlace = async () => {
    await updatePlace(
      place.id,
      {
        number_of_tables: numberOfTables,
        tables_json: tableCategories,
      },
      auth.token
    ).then((json) => {
      if (json) {
        setPlace(json);
        localStorage.setItem("place", JSON.stringify(json));
      }
    });
  };

  const onUpdateName = async () => {
    await updatePlace(
      place.id,
      {
        name: name,
      },
      auth.token
    ).then((json) => {
      if (json) {
        setPlace(json);
      }
    });
  };
  const onChangeName = (name) => {
    setName(name);
    showNameModal();
  };

  const updateName = () => {
    onUpdateName();
    hideNameModal();
  };

  useEffect(() => {
    onFetchPlace();
    fetchAllTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortCategories = (categories) => {
    return !!categories
      ? categories.sort((a, b) => a.priority - b.priority)
      : [];
  };

  const CategorySection = ({ category }) => (
    <div key={category.id} className="mb-5">
      <Container
        active={category.is_available}
        className="align-items-center justify-content-between mb-4 d-flex"
      >
        <h4
          style={{
            backgroundImage: `url(${category.background_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "darken",
            backgroundColor: "rgba(0,0,0, 0.3)",
          }}
          className="mb-0 mr-2 oneCategory"
        >
          <b style={{ color: "white" }}>{category.name_bs}</b>
        </h4>
        {/* <button className='categoryButton' key={category.id}
                    onClick={() => handleClick([category])}
                  >
                    <p style={{ fontWeight: 'bold', margin: '0px' }}>Edit {category.name} items</p>
                  </button> */}
        <div className="d-flex">
          <Button variant="link" onClick={() => onEditCategory(category)}>
            <BiEdit size={25} />
          </Button>
          <Button variant="link" onClick={() => onRemoveCategory(category.id)}>
            <AiOutlineDelete size={25} color="red" />
          </Button>
        </div>
      </Container>
      <div className="d-flex flex-column mb-4" style={{ gap: "20px" }}>
        {category?.menu_items.map((item) => (
          <MenuItem
            key={item.id}
            item={item}
            onEdit={() => {
              setSelectedItem(item);
              showModal();
            }}
            onRemove={() => onRemoveMenuItem(item.id)}
          />
        ))}
      </div>
      {/* {category.children
        ? category.children
            .sort((a, b) => a.priority - b.priority)
            .map((child) => <CategorySection key={child.id} category={child} />)
        : []} */}
      {sortCategories(category.children)?.map((child) => (
        <CategorySection key={child.id} category={child} />
      ))}
    </div>
  );

  return (
    <MainLayout>
      <Row>
        <Col lg={12}>
          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Button variant="link" onClick={onBack}>
                <IoMdArrowBack size={25} color="black" />
              </Button>
              <h3 className="mb-0 ml-2 mr-2">{place.name}</h3>
              <div className="d-flex">
                <Button variant="link" onClick={() => onChangeName(place)}>
                  <BiEdit size={25} />
                </Button>
                <Button variant="link" onClick={onRemovePlace}>
                  <AiOutlineDelete size={25} color="red" />
                </Button>
              </div>
            </div>
            {/* <button onClick={addTags}>Dodaj tag</button> */}
            <Button variant="link" onClick={showQRModal}>
              <AiOutlineQrcode size={25} />
            </Button>
            <Button variant="link" href={`/places/${params.id}/orders`}>
              <RiFileList3Line size={25} />
            </Button>
            <Button variant="link" href={`/places/${params.id}/settings`}>
              <FiSettings size={25} />
            </Button>
          </div>
        </Col>

        <Col md={4}>
          <Panel>
            <MenuItemForm
              place={place}
              onDone={() => onFetchPlace()}
              categoryPriority={categoryPriority}
              setCategoryPriority={setCategoryPriority}
              bgImage={bgImage}
              setBgImage={setBgImage}
              item={[]}
              mainCategories={mainCategories}
              categoryIsAvailable={isAvailable}
              setCategoryIsAvailable={setIsAvailable}
              featuredItemOptions={featuredItemOptions}
              tags={tags}
            />
          </Panel>
        </Col>
        <Col md={8}>
          {sortCategories(mainCategories)?.map((category) => (
            <CategorySection key={category.id} category={category} />
          ))}
        </Col>
      </Row>

      <Modal show={menuItemFormShow} onHide={hideModal} centered>
        <div style={{ backgroundColor: "white" }}>
          <Modal.Body>
            <h4 className="text-center">Menu Item</h4>
            <MenuItemForm
              place={place}
              onDone={() => {
                onFetchPlace();
                hideModal();
              }}
              setCategoryPriority={setCategoryPriority}
              setBgImage={setBgImage}
              item={selectedItem}
              mainCategories={mainCategories}
              featuredItemOptions={featuredItemOptions}
              tags={tags}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal show={editCategoryModal} onHide={hideEditModal} centered>
        <div style={{ backgroundColor: "white" }}>
          <Modal.Body>
            <h4 className="text-center">Edit Category</h4>
            <CategoryPopover
              onAddCategory={null}
              onUpdateCategory={onUpdateCategory}
              setCategory={setEditCategory}
              category={editCategory}
              bgImage={bgImage}
              setBgImage={setBgImage}
              categoryPriority={categoryPriority}
              parent_category={parent_category}
              setParentCategory={setParentCategory}
              setCategoryPriority={setCategoryPriority}
              place={place}
              isAvailable={isAvailable}
              setIsAvailable={setIsAvailable}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal show={nameModal} onHide={hideNameModal} centered>
        <div style={{ backgroundColor: "white" }}>
          <Modal.Title>
            <h4 className="text-center pt-2">Place name</h4>
          </Modal.Title>
          <Modal.Body>
            <Form.Control
              type="text"
              placeholder="Enter place name"
              defaultValue={place.name}
              onChange={(e) => setName(e.target.value)}
            />
            <Button
              className="mt-2"
              variant="standard"
              block
              onClick={updateName}
            >
              {"Update place name"}
            </Button>
          </Modal.Body>
        </div>
      </Modal>

      <QRCodeModal
        show={qrCode}
        onHide={hideQRModal}
        place={place}
        onDone={() => onFetchPlace()}
        centered
        onUpdatePlace={onUpdatePlace}
        tableCategories={tableCategories}
        setTableCategories={setTableCategories}
        numberOfTables={numberOfTables}
        setNumberOfTables={setNumberOfTables}
      />
    </MainLayout>
  );
};

export default Place;
